body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes rotatingShadow {
  0% {
    box-shadow: 10px 0 0 0 red;
  }
  25% {
    box-shadow: 0 10px 0 0 red;
  }
  50% {
    box-shadow: -10px 0 0 0 red;
  }
  75% {
    box-shadow: 0 -10px 0 0 red;
  }
  100% {
    box-shadow: 10px 0 0 0 red;
  }
}

@-webkit-keyframes rotatingShadow {
    0% {
      box-shadow: 10px 0 0 0 red;
    }
    25% {
      box-shadow: 0 10px 0 0 red;
    }
    50% {
      box-shadow: -10px 0 0 0 red;
    }
    75% {
      box-shadow: 0 -10px 0 0 red;
    }
    100% {
      box-shadow: 10px 0 0 0 red;
    }
  }
